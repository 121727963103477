<template>
  <div class="content_box">
    <loginHeader :title="$route.meta.title"></loginHeader>
    <div class="bind_box">
      <div class="input_box">
        <input type="text" v-model="mobile" placeholder="输入手机号码" />
      </div>
      <div class="input_box">
        <input type="text" v-model="code" placeholder="输入验证码" />
        <span class="after" @click="sendCode" v-if="codeSwitch"
          >获取验证码</span
        >
        <span v-else class="after" >{{ times }}s</span>
      </div>
      <div class="input_box">
        <input type="text" v-model="password" placeholder="6~16位数字或字母，区分大小写" />
      </div>
      <div class="input_box">
        <input type="text" v-model="rePassword" placeholder="确认密码" />
      </div>
    </div>

    <div>
      <span class="btn" @click="toReset">确定</span>
    </div>
  </div>
</template>
<script>
import {Toast} from "vant";
import "../../assets/css/loginRegister.scss";
import {forgetPassword, resetPassword} from "../../utils/api";
export default {
  data() {
    return {
      code: null,
      mobile: null,
      password: null,
      rePassword: null,
      codeSwitch: true, //验证码状态
      times: 60, //秒数
    };
  },
  methods: {
    sendCode() {
      let that = this;
      if (that.mobile) {
        forgetPassword({
          tel: that.mobile,
        })
          .then((res) => {
            that.codeSwitch = false;
            let timer = setInterval(() => {
              that.times--;
              if (that.times == 0) {
                that.codeSwitch = true;
                that.times = 60;
                clearInterval(timer);
              }
            }, 1000);
          })
          .catch((err) => {});
      } else {
        Toast("手机号码不能为空");
      }
    },
    toReset() {
      if(!this.mobile){
        Toast("手机号码不能为空");
        return false;
      }
      if(!this.code){
        Toast("验证码不能为空");
        return false;
      }
      if(!this.password){
        Toast("密码不能为空");
        return false;
      }
      let that = this;
      if (this.password == this.rePassword) {
        resetPassword({
          tel: this.mobile,
          msgCode: this.code,
          password: this.password,
        }).then(() => {
          Toast.success("重置密码成功");
          setTimeout(() => {
            Toast.clear();
            that.$router.replace("/login");
          }, 1000);
        });
      } else {
        Toast.success("两次密码不一致");
      }
    },
  },
};
</script>
